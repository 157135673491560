.logo-container .label {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 6px;
  background-color: #00000070;
  color: #ffffff;
  font-size: 0.8rem;
  min-width: 66px;
  text-align: center;
}
.logo-img-container {
  width: 140px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 3px;
  margin: auto;
}

.send-btn-wrapper {
  position: relative;
  margin: auto;
  width: 128px;
}

.mobile-view .send-btn-wrapper.small {
  width: 96px;
}

.send-btn {
  padding: 16px !important;
  width: 100%;
}

.mobile-view .logo-img-container {
  height: 100px;
  line-height: 100px;
}

.logo-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  overflow: hidden;
}

.extra-fees,
.time-ago,
.price-difference {
  font-size: 0.7rem;
  color: grey;
  min-height: 20px;
}

.cell-heading {
  font-size: 0.7rem;
  color: grey;
  margin-bottom: 0.4em;
}

.price-difference {
  font-size: 0.7rem;
  /* display: inline-block; */
}

.extra-fees .currency-value,
.price-difference .currency-value {
  font-size: inherit !important;
}

.price-difference.positive::before {
  color: green;
  content: '▲';
}

.price-difference.negative::before {
  content: '▼';
  color: red;
}

.is-best .currency-value {
  transform: scale(1.1);
  display: inline-block;
  font-weight: bold;
}

/* .is-best .currency-value::before {
  content: '';
  width: 18px;
  height: 18px;
  position: absolute;
  top: 2px;
  left: -20px;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/moneybag-icon.png');
  transform: scale(0.8);
} */

.is-best .duration {
  display: inline-block;
  transform: scale(1);
  font-weight: bold;
}

.is-best .duration::before {
  content: '';
  width: 20px;
  height: 24px;
  position: absolute;
  top: 0px;
  left: -20px;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/flash-icon.png');
}

.duration-wrapper + .ratings {
  margin-top: 3px;
}

.rates-table-container {
  position: relative;
  z-index: 2;
  top: -53px;
  max-width: 100%;
  width: 80%;
  width: clamp(700px, 1100px, 100%);
  /* padding-bottom: 64px; */
}

tr.MuiTableRow-head.fixed-header {
  position: fixed;
  top: 64px;
  z-index: 1000;
  display: flex;
  width: clamp(700px, 1100px, 100%);
}

tr.MuiTableRow-head.fixed-header th {
  flex-grow: 1;
  padding-top: 1em;
  padding-bottom: 1em;
}

.rates-table-container.mobile {
  top: 0px;
  width: 100%;
}

.rates-table-container > .MuiPaper-root {
  padding: 16px 0px 0px;
}

.rates-table-container > .MuiPaper-root > div {
  border-radius: 4px;
}

.MuiTableCell-root .currency-value {
  font-size: 1rem;
}

.rates-table-container .MuiTableSortLabel-icon {
  position: absolute;
  left: -28px;
}

.rates-table-container th.MuiTableCell-sizeSmall {
  padding: 6px 3px 6px 3px;
  text-align: center !important;
}

.rates-table-container td.MuiTableCell-sizeSmall {
  padding: 16px 16px 16px 16px;
}

.rates-table-container.small td.MuiTableCell-sizeSmall {
  padding: 6px 8px 6px 8px;
}
.rates-table-container.xsmall td.MuiTableCell-sizeSmall {
  padding: 3px 3px 3px 3px;
}
