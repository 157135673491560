html {
  scroll-behavior: smooth;
}

.main {
  background-color: #282c34;
  /* min-height: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: visible;
}

.main-loader {
  width: 100%;
}

.main .error-message {
  margin: 20px;
  color: #ff4d4d;
  text-align: center;
}

.about-us {
  min-height: 400px;
}

.footer {
  min-height: 100px;
}

.currency-value {
  display: inline-block;
}
.currency-value:first-letter {
  padding-right: 1px;
  font-size: 1.1em;
  font-weight: bold;
}
