.ribbon1 {
  position: absolute;
  top: -8px;
  right: 0px;
  z-index: 100;
  color: white;
  transform: scale(0.6);
}
.ribbon1:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 47px solid transparent;
  border-right: 47px solid transparent;
  border-top: 9px solid #2baf32;
  bottom: -6px;
  left: 0px;
}
.ribbon1 span {
  position: relative;
  display: block;
  text-align: center;
  background: #2baf32;
  font-size: 18px;
  line-height: 0.8;
  padding: 12px 8px 4px;
  border-top-right-radius: 8px;
  top: -2px;
}

.ribbon1 span:before,
.ribbon1 span:after {
  position: absolute;
  content: '';
}

.ribbon1 span:before {
  height: 6px;
  width: 6px;
  left: -6px;
  top: 0;
  background: #2baf32;
}
.ribbon1 span:after {
  height: 6px;
  width: 8px;
  left: -8px;
  top: 0;
  border-radius: 8px 8px 0 0;
  background: #118800;
}
